import React from 'react';
import './Footer.css';
import logo from "../../assets/logo.png"
import { MdOutlineArrowForwardIos, MdMail, MdCall } from "react-icons/md"
import { FaMapMarkerAlt } from "react-icons/fa";
import linkdin from "../../assets/linkedin.png"
import logopowered from "../../assets/logo2.png"


const Footer = () => {
    return (
        <footer className="container-fluid footer px-3 px-lg-5">
            <div className="footer-container row justify-content-between">
                <div className='col-lg-5 col-md-6 col-12 mb-4 mb-lg-0 '>
                    <div className="footer-section company-info">
                        <div>
                            <img src={logo} alt="Dream Ladder Logo" className="logo" />
                        </div>
                        <span><span className='footer_logo_style'>BlackHill</span></span>
                        <p className='mt-2'>
                            BlackHill is a leading growth fund employing both venture capital and private equity strategies to generate returns and impact. We follow a disciplined approach to investing and value creation. In their previous avatars, the founders have produced stellar investment returns
                        </p>
                    </div>
                </div>

                <div className='col-lg-6'>
                    <div className='row d-flex'>
                        <div className='col-lg-4 col-md-6 col-12 mb-4 mb-lg-0 '>
                            <div className="footer-section quick-links">
                                <h3>QUICK LINKS</h3>
                                <div className='menu'>
                                    <div className='sub_menu'><MdOutlineArrowForwardIos size={15} color='#FFFF11' /> <a href='#about'>About US</a></div>
                                    <div className='sub_menu'><MdOutlineArrowForwardIos size={15} color='#FFFF11' /> <a href='#services'>Focus Areas</a></div>
                                    <div className='sub_menu'><MdOutlineArrowForwardIos size={15} color='#FFFF11' /> <a href='#team'>Team</a></div>
                                    <div className='sub_menu'><MdOutlineArrowForwardIos size={15} color='#FFFF11' /> <a href='#esg'>ESG & Impact</a></div>
                                    <div className='sub_menu'><MdOutlineArrowForwardIos size={15} color='#FFFF11' /> <a href='#contact'>Contact Us</a></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-8 col-md-6 col-12 mb-4 mb-lg-0 '>
                            <div className="footer-section contact-us">
                                <h3>CONTACT US</h3>
                                <div className='menu'>
                                    <div className='sub_menu'><MdMail size={18} color='#FFFF11' /> <a>team@blackhillfund.com</a></div>
                                    {/* <div className='sub_menu'><MdCall size={18} color='#FFFF11' /> <a href="tel">(555)123-4567</a></div> */}
                                    <div className='sub_menu'><FaMapMarkerAlt size={18} color='#FFFF11' /> <a>1702/03 FLOOR NO 17 WING C DAFFODIL C GOVANDI E,NEELKANTH GARDENS CHSL PARSHWANATH MANDIR ROAD OPP JAIN TEMPLE,Mumbai,Mumbai,Maharashtra,India-400088</a></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bottom_section py-3  pe-5'>
                <div className='row align-items-center justify-content-between'>
                    <div className='col-md-6 col-12'><span className='about_made'>Copyright © 2024 BlackHill Fund Management - All Rights Reserved.</span></div>
                    <div className='col-md-6 col-12 d-flex justify-content-between mt-md-0 mt-4'>
                        <div className='powered_by'>
                            <span className='powered_by_text'>powered by</span>
                            <img src={logopowered} />
                        </div>
                        <div className='d-flex'>
                            <a href="https://www.linkedin.com/company/blackhillfund" target="_blank" rel="noopener noreferrer">
                                <img src={linkdin} alt="LinkedIn" />
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </footer>
    );
};

export default Footer;
