import React from 'react'
import "./Map.css"
const Map = () => {
    return (
        <div className='map_style'>
            <iframe src="
https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8903.819671333202!2d72.91416929214697!3d19.055741142500683!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c60e0dd1fb41%3A0xaad258204ba295b5!2sDaffodil%2C%20Neelkanth%20garden!5e0!3m2!1sen!2sin!4v1720445932439!5m2!1sen!2sin"
                width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    )
}

export default Map
